@function rem($val) {
  @return ($val/14)*1rem;
}

body {
  /* Firefox 64+ */
  scrollbar-width: none;
  scrollbar-color: transparent transparent;

  /* webkit */
  ::-webkit-scrollbar {
    display: none;
  }
}

/* 设置滚动条样式 */
::-webkit-scrollbar {
  display: none;
  width: 12px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

::-webkit-scrollbarthumb:window-inactive {
  background-color: rgba(255, 0, 0, 0.4);
}

a {
  text-decoration: none;

  &:hover {
    color: #e60819
  }
}

.swiper-container {
  height: 100vh;
  width: 100%;
  position: relative;
  -webkit-user-select: none;
  user-select: none;

  .container {
    height: 100vh;
    background-color: #fff;
  }
}

.parent-wrapper {
  transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
}

.swiper-slide {
  position: relative;
  color: #fff;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

/* 步骤1 start */
.step-1 {
  background-size: cover;

  .step-1-content {
    display: flex;
    background: rgba(0, 0, 0, 0.3);
  }

  .step-1-left {
    position: relative;
    height: 100vh;
    background: #FFF;
    background-size: cover;
    opacity: 0;
    width: 0;
    transition: 0.2s ease 0.4s;
    overflow: hidden;

    img.k {
      position: absolute;
      width: auto;
      height: 57%;
      left: 10%;
      top: 10%;
    }

    .content {
      position: absolute;
      // left: 50%;
      top: 50%;
      width: 820px;
      transform: translateY(-50%);

      .timeline {
        position: absolute;
        left: 0;
        top: 50%;
        width: 820px;
        height: 1px;
        background: #d8d8d8;
      }

      .line {
        position: absolute;
        color: #333;
        transition: color .3s;
        cursor: pointer;

        &::after {
          content: '';
          position: absolute;
          left: 26px;
          width: 14px;
          height: 14px;
          transform: translateY(-50%);
          border-radius: 50%;
          background-color: #d8d8d8;
          transition: background-color .3s;
        }

        // .line:nth-child(2n)::after {
        //   top: -33px;
        // }

        &:nth-child(even) {
          top: 33px;

          &::after {
            top: -33px;
          }
        }

        &:nth-child(odd) {
          bottom: 0;
        }


        &:hover {
          color: #e60819;

          &::after {
            background-color: #e60819;
          }
        }

        .nums {
          font-size: rem(42);
          font-weight: bold;
          line-height: 1.5;

          span {
            font-size: rem(16);
          }
        }

        .desc {
          margin-bottom: 1.937rem;
          font-size: 1.142rem;
          line-height: 1;
          color: #333;
        }
      }
    }

    .title {
      height: 2.285rem;
      line-height: 1;
      margin-bottom: 24px;
      padding-left: 9.57rem;
      font-size: rem(32);
      font-weight: bold;
      color: #e60819;
    }

    .sub-title {
      height: 1.333rem;
      line-height: 1;
      margin-bottom: 3rem;
      padding-left: 9.57rem;
      color: #888;
      font-size: ren(16);
    }




    // .view-more {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   width: 130px;
    //   height: 44px;
    //   line-height: 44px;
    //   margin: auto;
    //   margin-top: 24.3rem;

    //   // margin-left: 238px;
    //   text-decoration: none;
    //   text-align: center;
    //   font-size: 12px;
    //   color: #333;
    //   border: 1px solid #333;
    //   transition: all .3s;
    //   cursor: pointer;
    // }

    // .view-more:hover {
    //   background-color: #e60819;
    //   color: #fff;
    //   border-color: #fff;
    // }
    .more {
      display: inline-block;
      position: relative;
      margin: auto;
      margin-top: 24.3rem;
      padding: rem(15) rem(30);
      font-size: rem(12);
      text-decoration: none;
      color: #333;
      border: 1px solid #333333;
      transition: all .3s;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 0%;
        margin: auto;
        background-color: #e60819;
        transition: all .3s;
      }

      span {
        display: inline-block;
        position: relative;
      }

      .more-icon {
        margin-left: rem(15);
        width: rem(8);
        height: rem(8);
        aspect-ratio: 1;
        background-image: url('/image/emc/more.png');
        background-position: top;
        background-repeat: no-repeat;
        background-size: 100%;
      }

      &:hover {
        color: #fff;
        border-color: #e60819;

        &::before {
          width: 100%;
        }

        .more-icon {
          background-position: bottom;
        }
      }
    }
  }
}

.swiper-slide-active .step-1-left {
  opacity: 1;
  width: 960px;
  transition: 1s ease 0.6s;
}

.step-1-right {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.2s ease 0.4s;

  .logo {
    width: 170px;
    height: 58px;
  }
}

.swiper-slide-active .step-1-right {
  opacity: 1;
  width: 960px;
  transition: 1s ease 0.6s;
}

/* 步骤1 end */

/* 步骤2 start */
.step-2 {
  .swiper-slide::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: linear-gradient(180deg, rgba(51, 51, 51, 0.3) 0%, rgba(51, 51, 51, 0) 100%);
  }
}

.child-content {
  position: absolute;
  left: 6.429rem;
  bottom: 11.8rem;
  color: #fff;
  opacity: 0;
  transition: 0.2s ease 0.4s;
  transform: translate3d(0, 3.571rem, 0);

  .title {
    position: relative;
    // top: -3.571rem;
    // left: 0;
    font-size: 2.285rem;
    font-weight: bold;
    z-index: 1;

    &::after {
      content: ' ';
      position: absolute;
      top: -0.285rem;
      left: -0.8rem;
      width: 2.57rem;
      height: 2.57rem;
      background: #e60819;
      z-index: -1;
    }
  }

  .content {
    font-size: 2.571rem;
  }
}

.swiper-slide-active .swiper-slide-active .child-content {
  opacity: 1;
  transition: 0.5s ease 1s;
  transform: translate3d(0, 0, 0);
}

/* 步骤2 end */

/* 父分页器 start */
.parent-pagination {
  display: flex;
  right: 78px !important;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  z-index: 1;
}

.parent-pagination .swiper-pagination-bullet {
  width: 5px;
  height: 5px;
  line-height: 5px;
  margin: 20px 0 !important;
  font-size: 14px;
  font-family: Poppins, Poppins;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  background: #fff;
  color: #fff;
  opacity: 0.6;
  font-style: normal;
}

.parent-pagination .swiper-pagination-bullet-active {
  width: auto;
  height: 24px;
  line-height: 24px;
  background: transparent;
  opacity: 1;
}

/* 父分页器 end */

/* 子分页器 start */
.child-pagination {
  bottom: 48px !important;
}

.child-pagination .swiper-pagination-bullet {
  width: 64px;
  height: 3px;
  margin: 0 16px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 0;
}

.child-pagination .swiper-pagination-bullet-active {
  background: #fff;
}

/* 子分页器 end */

@media screen and (min-width: 768px) {}

@media screen and (min-width: 1024px) {}

/* 步骤3 */
.step-3 {

  background: linear-gradient(90deg, #0E1014 0%, #414859 50%, #0E1014 100%);

  .swiper-container {
    position: absolute;
    width: 55.5vw;
    height: 60vh;
    left: 0;
    right: 0;
    top: 14.5vh;
    // bottom: 0;
    margin: auto;
    overflow: visible;

    .swiper-slide {
      overflow: visible;

      &.swiper-slide-next,
      &.swiper-slide-prev {
        .content {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: 80%;
          height: 80%;

        }
      }
    }

    .button-prev,
    .button-next {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      top: 50%;
      width: rem(42);
      height: rem(42);
      transform: translateY(-50%);
      font-family: var(--fa-style-family, "Font Awesome 6 Free");
      font-weight: var(--fa-style, 900);
      color: #fff;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 8px;
      backdrop-filter: blur(3px);
      cursor: pointer;

      &:hover {
        background: #E60819;
        box-shadow: 0px 13px 17px 0px rgba(215, 77, 77, 0.5);
      }
    }

    .button-prev {
      left: rem(-90);

      &::before {
        content: '\f053';
      }
    }

    .button-next {
      right: rem(-90);

      &::before {
        content: '\f054';
      }
    }

  }

  .content {
    position: relative;
    // margin: 0 1rem;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 16px;
    box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    transition: all .3s;

    // &::before {
    //   content: '';
    //   display: block;
    //   position: absolute;
    //   top: -1px;
    //   bottom: -1px;
    //   left: -1px;
    //   right: -1px;
    //   margin: auto;
    //   // background: linear-gradient(180deg, rgba(51, 56, 70, 0) 0%, #333846 100%);
    //   // background: rgba(0, 0, 0, 0.5);
    // }

    img {
      display: block;
      position: absolute;
      width: 110%;
      height: 110%;
      top: -5%;
      bottom: -5%;
      left: -5%;
      right: -5%;
      margin: auto;
    }

    .detail {
      position: absolute;
      margin: 2rem;
      left: 0;
      right: 0;
      bottom: 0;
      font-size: rem(20);
      font-weight: bold;
      white-space: pre-line;
      line-height: 1.5;
      color: #fff;
    }
  }

  .pagination {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    margin-top: rem(56);
    border-bottom: 1px solid #fff;


    .item {
      position: relative;
      padding: rem(24) 0;
      font-size: rem(24);
      line-height: 1;
      color: #fff;
      opacity: .5;
      cursor: pointer;
      transition: opacity .3s;

      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 0;
        height: 6px;
        background-color: #E60819;
        transition: width .3s;
      }

      &.active {
        font-weight: bold;
        opacity: 1;

        &::before {
          width: 100%;
        }
      }

      &:hover {
        opacity: 1;
      }
    }
  }

}

/* 步骤4 */
.step-4 {
  position: relative;
  background: linear-gradient(90deg, #0E1014 0%, #414859 100%);

  .flex {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    position: relative;
    top: 4.715rem;
    padding: 0 rem(8);
    height: calc(100vh - 4.715rem);
  }

  .col {
    display: flex;
    align-items: stretch;
    transition: all .3s;

    .content {
      flex: 1 1 auto;
      margin: rem(16) rem(8);
      text-align: center;
      text-decoration: none;
      color: #fff;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 16px;
      overflow: hidden;
      cursor: pointer;

      &::before {
        content: 0;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background: linear-gradient(180deg, #4686C4 0%, rgba(70, 134, 196, 0) 100%);
      }

      .title {
        margin-top: rem(90);
        margin-bottom: rem(26);
        font-size: rem(24);
        font-weight: bold;
        line-height: 1.5;
      }

      .detail {
        opacity: 0;
        font-size: rem(16);
        line-height: 1.8;
        white-space: pre;
        transition: opacity .3s;
      }
    }

    &:hover {
      flex-grow: 1.5;

      .content {
        .detail {
          opacity: 1;
        }
      }
    }

  }


}

/* 步骤5 */
.step-5 {
  .bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    height: 60vh;
    background-color: #999;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .parent-content {
    position: relative;
    padding-top: 19vh;
    padding-left: 12vw;
    padding-right: 12vw;

    .cat {
      margin-bottom: 15vh;
      color: #fff;

      .title {
        margin-bottom: rem(10);
        font-size: rem(32);
        font-weight: bold;
        line-height: 1.5;
      }

      .desc {
        font-size: rem(16);
        line-height: 1.75;
        white-space: pre-wrap;
      }
    }

    .swiper-container {
      height: auto;
      overflow: visible;

      .swiper-slide {
        width: auto;
        height: 40vh;

        .content {
          width: 36vw;
          height: 40vh;
          position: relative;
          border-radius: rem(16);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          overflow: hidden;

          &::before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background: linear-gradient(180deg, rgba(50, 52, 55, 0) 0%, #323437 100%);
            opacity: 0;
            transition: opacity .3s;
          }

          .top {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            padding: rem(16);
            font-size: rem(16);
            line-height: 1.5;
            background-color: rgba(89, 0, 0, 0.7);
            border-radius: 0 0 rem(16) 0;

            .icon {
              display: inline-block;
              margin-right: rem(8);
              height: rem(16);
            }
          }

          .bottom {
            position: absolute;
            bottom: rem(32);
            left: 0;
            right: 0;
            margin: auto rem(24);
            color: #fff;
            opacity: 0;
            transition: opacity .3s;

            .title {
              margin: 0;
              padding: rem(8) 0;
              font-size: rem(20);
              border-bottom: 1px solid #D8D8D8;
            }

            .desc {
              margin: 0;
              padding: rem(7) 0;
              font-size: rem(16);
            }

          }

          &:hover {
            &::before {
              opacity: 1;
            }

            .bottom {
              opacity: 1;
            }
          }
        }
      }

      .swiper-pagination,
      .swiper-scrollbar {
        position: absolute;
        height: rem(8);
        left: 0;
        top: 100%;
        margin-top: rem(32);
        border-radius: rem(4);
        overflow: hidden;

        .swiper-pagination-progressbar-fill,
        .swiper-scrollbar-drag {
          background-color: #E60819;
        }
      }
    }
  }
}

/* 步骤6 */
.step-6 {
  position: relative;
  background: linear-gradient(180deg, #F2F2F2 0%, #E0E6EC 100%);

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 50vh;
    background: linear-gradient(180deg, rgba(51, 56, 70, 0.3) 0%, rgba(51, 56, 70, 0) 100%);
  }

  .map-box {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    img {
      width: 100vw;
      height: auto;
    }

    .point {
      position: absolute;
      width: rem(10);
      height: rem(10);
      margin: auto;
      background-color: #E60819;
      border-radius: 50%;

      @keyframes point {
        0% {
          width: rem(10);
          height: rem(10);
          opacity: .5;
        }

        100% {
          width: rem(20);
          height: rem(20);
          opacity: .3;
        }
      }

      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: .5;
        border-radius: 50%;
        border: 1px solid #E60819;
        animation-name: point;
        animation-duration: 1.2s;
        animation-iteration-count: infinite;
      }

      &::after {
        animation-delay: .6s;
      }
    }
  }

  .content {
    position: relative;
    top: 54vh;
    left: 12vw;

    .top {
      margin-bottom: rem(48);

      p {
        font-size: rem(16);
        line-height: 1.5;
        color: #333;

        .num {
          display: inline-block;
          font-size: rem(42);
          font-weight: bold;
          line-height: 1;
          color: #E60819;
        }

        &.desc {
          margin: 0;
          color: #888;
        }
      }
    }

    .bottom {
      .title {
        margin-bottom: rem(8);
        font-size: rem(32);
        font-weight: bold;
        line-height: 1.5;
        color: #333;
      }

      .desc {
        font-size: rem(16);
        line-height: 1.5;
        white-space: pre-line;
        color: #888;
      }
    }
  }
}

/* 步骤7 */
.step-7 {
  background: #F2F2F2;

  .head {
    position: relative;
    height: 30vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background: linear-gradient(180deg, #08121D 0%, rgba(53, 53, 53, 0) 100%);
    }

    .content {
      position: absolute;
      top: 48%;
      left: 0;
      right: 0;
      margin: auto;
      font-size: rem(16);
      line-height: 1.5;
      text-align: center;
      color: #fff;

      span {
        display: inline-block;
        margin: 0 rem(5);
      }

      .big {
        font-size: rem(42);
        color: #E60819;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;
    margin-top: rem(40);
    position: relative;
    height: 50vh;
    background: transparent;

    .col {
      position: relative;
      margin: rem(12);
      // width: 100%;
      overflow: hidden;

      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        margin: auto;
        width: rem(264);
      }

      &::before {
        left: 0;
        background: linear-gradient(90deg, rgba(242, 242, 242, 1) 0%, rgba(242, 242, 242, 0) 100%);
      }

      &::after {
        right: 0;
        background: linear-gradient(90deg, rgba(242, 242, 242, 0) 0%, rgba(242, 242, 242, 1) 100%);
      }

      @keyframes case {
        0% {
          transform: translateX(0);
        }

        100% {
          transform: translateX(-50%);
        }
      }

      .items {
        display: flex;
        align-items: stretch;
        height: 100%;
        position: absolute;
        animation-name: case;
        animation-duration: 45s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 rem(12);
          height: 100%;
          aspect-ratio: 2;
          background-color: #fff;
          border-radius: rem(8);
          cursor: pointer;
          filter: grayscale(100%); // 黑白

          img {
            max-width: 100%;
            max-height: 100%;
          }

          &:hover {
            filter: none;
          }
        }
      }

      &:nth-child(even) {
        .items {
          animation-direction: reverse;
        }
      }

      &:hover .items {
        animation-play-state: paused;
      }
    }
  }
}

/* 步骤8 */
.step-8 {
  background: #F2F2F2;

  .head {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 42vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5vh;
    background: transparent;

    .h1 {
      margin-bottom: rem(24);
      font-size: rem(32);
      font-weight: bold;
      line-height: 1.5;
      color: #fff;
    }

    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;

      .item {
        display: block;
        position: relative;
        flex: 0 0;
        flex-grow: 1;
        margin: rem(8) 0;

        .box {
          margin: 0;
          height: 100%;
          align-items: stretch;
          background: #fff;
          border-radius: 8px;
          overflow: hidden;

          &:hover {
            box-shadow: 0px 9px 21px 0px rgba(34, 45, 53, 0.1);
          }
        }

        .image {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }

        .text {
          padding: rem(24);
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: stretch;

          .title {
            margin: 0 0 rem(8) 0;
            font-size: rem(16);
            font-weight: bold;
            line-height: 1;
            color: #333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .center {
            flex: 1 1 auto;

            .desc {
              display: -webkit-box;
              margin: 0;
              font-size: rem(16);
              line-height: 1.5;
              color: #888;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }

          .link {
            justify-self: flex-end;
            font-size: rem(14);
            text-decoration: none;
            color: #38C59F;
          }
        }
      }
    }

    .right {
      .content {
        margin: rem(8) 0;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 64vh;
        background-color: #fff;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 8px;
        overflow: hidden;

        &:hover {
          box-shadow: 0px 9px 21px 0px rgba(34, 45, 53, 0.1);
        }

        .more {
          text-align: center;

          img {
            margin-bottom: rem(18);
            width: rem(44);
            height: rem(44);
          }

          p {
            font-size: rem(16);
            color: #333;
            transition: color .3s;

          }
        }

        &:hover {
          .more {
            p {
              color: #e60819;
            }
          }
        }
      }
    }
  }
}

.step-9 {
  .parent-content {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .end {
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
      height: auto;
      background-color: transparent !important;
    }
  }

  #footer {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    padding-top: rem(50);

    .detail {
      margin-bottom: rem(150);
      color: #333
    }

    .bottom {
      margin-top: 0;
    }
  }
}